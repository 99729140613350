@charset "UTF-8";
/*$pagination-color: #000000;
$pagination-hover-color: $MainColor;
$pagination-hover-border-color: $MainColor;
$pagination-hover-bg: none;
$pagination-active-color: $MainColor;
$pagination-active-bg: none;
$pagination-active-border-color: $MainColor;*/
/*for later use when build a table content using bootstrap col classes so different rows won't get problem with different length of word or content*/
.table-responsive .row {
  flex-wrap: nowrap !important;
}
.table-responsive .row .col, .table-responsive .row [class*=col-] {
  overflow-wrap: break-word !important;
}

.input-group {
  direction: ltr;
}

.form-row label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.form-row label {
  direction: ltr;
}

.form-group label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.HorizontalScrollContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.HorizontalScrollContainer > div:first-child {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-bottom: 18px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.HorizontalScrollContainer > div > div {
  direction: rtl;
}
.HorizontalScrollContainer > div .Item {
  float: left;
}
.HorizontalScrollContainer .ScrollBack, .HorizontalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

.VerticalScrollContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.VerticalScrollContainer > div:first-child {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.VerticalScrollContainer > div > div {
  direction: rtl;
}
.VerticalScrollContainer > div .Item {
  float: left;
}
.VerticalScrollContainer .ScrollBack, .VerticalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

/*responsive grouping*/
.ClassName {
  width: 10px !important;
}

@media (min-width: 576px) {
  .ClassName {
    width: 20px !important;
  }
}
@media (min-width: 768px) {
  .ClassName {
    width: 30px !important;
  }
}
@media (min-width: 992px) {
  .ClassName {
    width: 40px !important;
  }
}
.customeuploadinput {
  /* Header */
  /* Top Navigation Style */
  /* Demo links */
  /* Content */
  /* Related demos */
  /* style 1 */
  /* style 2 */
  /* style 3 */
  /* style 4 */
  /* style 5 */
  /* style 6 */
}
.customeuploadinput .clearfix:before,
.customeuploadinput .clearfix:after {
  display: table;
  content: "";
}
.customeuploadinput .clearfix:after {
  clear: both;
}
.customeuploadinput body {
  font-family: Avenir, "Helvetica Neue", "Lato", "Segoe UI", Helvetica, Arial, sans-serif;
  color: #4b0f31;
  background-color: #f1e5e6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.customeuploadinput a {
  outline: none;
  color: #d3394c;
  text-decoration: none;
}
.customeuploadinput a:hover,
.customeuploadinput a:focus {
  color: #722040;
}
.customeuploadinput .hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}
.customeuploadinput .codrops-header {
  padding: 2em 1em 4em;
  text-align: center;
}
.customeuploadinput .codrops-header h1 {
  margin: 0.5em 0 0;
  letter-spacing: -1px;
  font-size: 3em;
  line-height: 1;
}
.customeuploadinput .codrops-header h1 span {
  display: block;
  padding: 0.5em 0 1em;
  color: #999;
  font-weight: normal;
  font-size: 0.45em;
}
.customeuploadinput .codrops-links {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}
.customeuploadinput .codrops-links::after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  content: "";
  transform: rotate3d(0, 0, 1, 22.5deg);
}
.customeuploadinput .codrops-icon {
  display: inline-block;
  margin: 0.5em;
  padding: 0em 0;
  width: 1.5em;
  text-decoration: none;
}
.customeuploadinput .codrops-icon span {
  display: none;
}
.customeuploadinput .codrops-icon:before {
  margin: 0 5px;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: "codropsicons";
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
.customeuploadinput .codrops-icon--drop:before {
  content: "\e001";
}
.customeuploadinput .codrops-icon--prev:before {
  content: "\e004";
}
.customeuploadinput .codrops-demos {
  margin: 2em 0 0;
}
.customeuploadinput .codrops-demos a {
  display: inline-block;
  margin: 0 0.5em;
}
.customeuploadinput .codrops-demos a.current-demo {
  color: #333;
}
.customeuploadinput .content {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 3em 0;
}
.customeuploadinput .content footer {
  color: #b39295;
  margin-top: 40px;
}
.customeuploadinput .content footer a:hover,
.customeuploadinput .content footer a:focus {
  color: #4b0f31;
}
.customeuploadinput .box {
  background-color: #dfc8ca;
  padding: 6.25rem 1.25rem;
}
.customeuploadinput .box + .box {
  margin-top: 2.5rem;
}
.customeuploadinput .content--related {
  text-align: center;
  font-weight: bold;
  padding-top: 4em;
}
.customeuploadinput .media-item {
  display: inline-block;
  padding: 1em;
  vertical-align: top;
  transition: color 0.3s;
}
.customeuploadinput .media-item__img {
  max-width: 100%;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.customeuploadinput .media-item:hover .media-item__img,
.customeuploadinput .media-item:focus .media-item__img {
  opacity: 1;
}
.customeuploadinput .media-item__title {
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
}
@media screen and (max-width: 50em) {
  .customeuploadinput .codrops-header {
    padding: 3em 10% 4em;
  }
}
@media screen and (max-width: 40em) {
  .customeuploadinput .codrops-header h1 {
    font-size: 2.8em;
  }
}
.customeuploadinput .js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.customeuploadinput .inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}
.customeuploadinput .no-js .inputfile + label {
  display: none;
}
.customeuploadinput .inputfile:focus + label,
.customeuploadinput .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.customeuploadinput .inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}
.customeuploadinput .inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}
.customeuploadinput .inputfile-1 + label {
  color: #f1e5e6;
  background-color: #d3394c;
}
.customeuploadinput .inputfile-1:focus + label,
.customeuploadinput .inputfile-1.has-focus + label,
.customeuploadinput .inputfile-1 + label:hover {
  background-color: #722040;
}
.customeuploadinput .inputfile-2 + label {
  color: #d3394c;
  border: 2px solid currentColor;
}
.customeuploadinput .inputfile-2:focus + label,
.customeuploadinput .inputfile-2.has-focus + label,
.customeuploadinput .inputfile-2 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-3 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-3:focus + label,
.customeuploadinput .inputfile-3.has-focus + label,
.customeuploadinput .inputfile-3 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-4 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-4:focus + label,
.customeuploadinput .inputfile-4.has-focus + label,
.customeuploadinput .inputfile-4 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d3394c;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}
.customeuploadinput .inputfile-4:focus + label figure,
.customeuploadinput .inputfile-4.has-focus + label figure,
.customeuploadinput .inputfile-4 + label:hover figure {
  background-color: #722040;
}
.customeuploadinput .inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
.customeuploadinput .inputfile-5 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-5:focus + label,
.customeuploadinput .inputfile-5.has-focus + label,
.customeuploadinput .inputfile-5 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: #d3394c;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}
.customeuploadinput .inputfile-5:focus + label figure,
.customeuploadinput .inputfile-5.has-focus + label figure,
.customeuploadinput .inputfile-5 + label:hover figure {
  background-color: #722040;
}
.customeuploadinput .inputfile-5 + label figure::before,
.customeuploadinput .inputfile-5 + label figure::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.customeuploadinput .inputfile-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}
.customeuploadinput .inputfile-5 + label figure::after {
  border-bottom: 20px solid #722040;
  border-right: 20px solid transparent;
}
.customeuploadinput .inputfile-5:focus + label figure::after,
.customeuploadinput .inputfile-5.has-focus + label figure::after,
.customeuploadinput .inputfile-5 + label:hover figure::after {
  border-bottom-color: #d3394c;
}
.customeuploadinput .inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
.customeuploadinput .inputfile-6 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-6 + label {
  border: 1px solid #c0c0c0;
  padding: 0;
  border-radius: 5px;
}
.customeuploadinput .inputfile-6:focus + label,
.customeuploadinput .inputfile-6.has-focus + label,
.customeuploadinput .inputfile-6 + label:hover {
  border-color: #722040;
}
.customeuploadinput .inputfile-6 + label span,
.customeuploadinput .inputfile-6 + label strong {
  padding: 6px 10px;
  /* 10px 20px */
}
.customeuploadinput .inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
.customeuploadinput .inputfile-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #e52727;
  display: inline-block;
}
.customeuploadinput .inputfile-6:focus + label strong,
.customeuploadinput .inputfile-6.has-focus + label strong,
.customeuploadinput .inputfile-6 + label:hover strong {
  background-color: #722040;
}
@media screen and (max-width: 50em) {
  .customeuploadinput .inputfile-6 + label strong {
    display: block;
  }
}

.tooltip-inner {
  font-size: 0.7rem;
}

.infotable table th {
  background-color: #ed912a;
  color: black;
}

#placeholdersection100 .items_block {
  touch-action: pan-y;
}
#placeholdersection100 .HorizontalScrollContainer {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none;
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
#placeholdersection100 .HorizontalScrollContainer__left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, #f7dab8, transparent);
  opacity: 0.5;
}
#placeholdersection100 .HorizontalScrollContainer__right:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(to left, #f7dab8, transparent);
  opacity: 0.5;
}
#placeholdersection100 .firsttable tr, #placeholdersection100 .updates tr, #placeholdersection100 .sandbox tr, #placeholdersection100 .bandwidth tr, #placeholdersection100 .support tr, #placeholdersection100 .development tr, #placeholdersection100 .portal tr {
  height: 75px;
}
#placeholdersection100 .Backups tr {
  height: 90px;
}
#placeholdersection100 td {
  direction: ltr;
}
#placeholdersection100 .active {
  display: flex !important;
}
#placeholdersection100 .homeboxproduct .plansheader, #placeholdersection100 .homeboxproduct .plansfooter {
  height: 50px;
}
#placeholdersection100 .homeboxproduct .plansheader tr, #placeholdersection100 .homeboxproduct .plansfooter tr {
  height: 50px;
}
#placeholdersection100 .homeboxproduct .plansheader tr a, #placeholdersection100 .homeboxproduct .plansfooter tr a {
  padding: 8px;
  background-color: #161e2e;
  color: #ffffff;
}
#placeholdersection100 .homeboxproduct .plansheader tr a:before, #placeholdersection100 .homeboxproduct .plansfooter tr a:before {
  content: "> ";
  font-size: 14px;
  color: #ffffff;
}
#placeholdersection100 .homeboxproduct .plansheader tr a:hover, #placeholdersection100 .homeboxproduct .plansfooter tr a:hover {
  text-decoration: none;
}
#placeholdersection100 .homeboxproduct .plansheader {
  position: sticky;
  top: 72px;
  z-index: 5;
  background-color: #ed912a;
  opacity: 1;
  border-bottom: #161e2e 1px solid;
}
#placeholdersection100 .homeboxproduct .plansheader .table {
  color: #161e2e !important;
}
#placeholdersection100 .homeboxproduct .plansfooter {
  position: sticky;
  bottom: 0;
  z-index: 4;
  background-color: #ed912a;
  opacity: 1;
  border-top: 1px solid #161e2e;
  color: #161e2e;
}
#placeholdersection100 .homeboxproduct .sticky {
  position: sticky;
  height: 60px;
  top: 113.5px;
  z-index: 3;
  background-color: #ed912a;
  opacity: 1;
}
#placeholdersection100 .homeboxproduct .fixedwidth {
  width: 200px;
}
#placeholdersection100 .homeboxproduct .title {
  align-items: flex-start;
}
#placeholdersection100 .homeboxproduct .row {
  margin-right: 0;
  margin-left: 0;
  flex-wrap: nowrap;
}
#placeholdersection100 .homeboxproduct .row .Item .btn {
  background-color: #ed912a !important;
  color: black !important;
}
#placeholdersection100 .homeboxproduct .tablesection {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-start;
  color: #161e2e;
}
#placeholdersection100 .homeboxproduct .tablesection button {
  border: unset;
  cursor: pointer;
  background-color: transparent;
}
#placeholdersection100 .homeboxproduct .tablesection button:focus {
  outline: none;
  border: unset;
  cursor: pointer;
  background-color: transparent;
}
#placeholdersection100 .homeboxproduct table {
  border-spacing: 0px !important;
}
#placeholdersection100 .homeboxproduct .table th, #placeholdersection100 .homeboxproduct .table td {
  padding: 0 !important;
  border-top: none !important;
}
#placeholdersection100 .homeboxproduct td {
  vertical-align: middle !important;
  text-align: center;
  padding: 0 !important;
  /* border-top: none !important; */
}
#placeholdersection100 .homeboxproduct td button {
  border: unset;
  cursor: pointer;
  background-color: transparent;
}
#placeholdersection100 .homeboxproduct td button:focus {
  outline: none;
  border: unset;
  cursor: pointer;
  background-color: transparent;
}

#placeholdersection100 {
  background: #ffffff;
}
#placeholdersection100.HorizontalScrollContainer, #placeholdersection100 .HorizontalScrollContainer {
  height: auto;
}
#placeholdersection100.HorizontalScrollContainer > div > div, #placeholdersection100 .HorizontalScrollContainer > div > div {
  height: auto;
}
#placeholdersection100.HorizontalScrollContainer > div .Item, #placeholdersection100 .HorizontalScrollContainer > div .Item {
  height: auto;
  width: 200px;
}
#placeholdersection100.HorizontalScrollContainer .ScrollBack, #placeholdersection100 .HorizontalScrollContainer .ScrollBack {
  left: 0px;
  top: 0px;
}
#placeholdersection100.HorizontalScrollContainer .ScrollNext, #placeholdersection100 .HorizontalScrollContainer .ScrollNext {
  right: 0px;
  top: 0px;
}
#placeholdersection100 .ScrollBack, #placeholdersection100 .ScrollNext {
  box-shadow: none !important;
  z-index: 2;
  font-size: 30px;
  color: #161e2e;
  height: 100%;
}
#placeholdersection100 .ScrollNext:hover {
  cursor: pointer;
}
#placeholdersection100 .ScrollBack:hover {
  cursor: pointer;
}

.infotable table th {
  font-size: 11px !important;
}
.infotable table td {
  font-size: 11px !important;
}

#placeholdersection100 .firsttable tr, #placeholdersection100 .updates tr, #placeholdersection100 .sandbox tr, #placeholdersection100 .bandwidth tr, #placeholdersection100 .support tr, #placeholdersection100 .development tr, #placeholdersection100 .portal tr {
  height: 45px;
}
#placeholdersection100 .Backups tr {
  height: 60px;
}
#placeholdersection100 .homeboxproduct div .Item, #placeholdersection100 .homeboxproduct .title {
  width: 170px !important;
}
#placeholdersection100 .homeboxproduct div .Item td, #placeholdersection100 .homeboxproduct .title td {
  font-size: 11px !important;
}

@media (min-width: 768px) {
  .infotable table th {
    font-size: 13px !important;
  }
  .infotable table td {
    font-size: 13px !important;
  }
  #placeholdersection100 .firsttable tr, #placeholdersection100 .updates tr, #placeholdersection100 .sandbox tr, #placeholdersection100 .bandwidth tr, #placeholdersection100 .support tr, #placeholdersection100 .development tr, #placeholdersection100 .portal tr {
    height: 55px;
  }
  #placeholdersection100 .Backups tr {
    height: 65px;
  }
  #placeholdersection100 .homeboxproduct div .Item, #placeholdersection100 .homeboxproduct .title {
    width: 190px !important;
  }
  #placeholdersection100 .homeboxproduct div .Item td, #placeholdersection100 .homeboxproduct .title td {
    font-size: 12px !important;
  }
}
@media (min-width: 992px) {
  .infotable table th {
    font-size: 14px !important;
  }
  .infotable table td {
    font-size: 14px !important;
  }
  #placeholdersection100 .firsttable tr, #placeholdersection100 .updates tr, #placeholdersection100 .sandbox tr, #placeholdersection100 .bandwidth tr, #placeholdersection100 .support tr, #placeholdersection100 .development tr, #placeholdersection100 .portal tr {
    height: 65px;
  }
  #placeholdersection100 .Backups tr {
    height: 85px;
  }
  #placeholdersection100 .homeboxproduct div .Item, #placeholdersection100 .homeboxproduct .title {
    width: 210px !important;
  }
  #placeholdersection100 .homeboxproduct div .Item td, #placeholdersection100 .homeboxproduct .title td {
    font-size: 14px !important;
  }
}
@media (min-width: 1200px) {
  .infotable table th {
    font-size: 15px !important;
  }
  .infotable table td {
    font-size: 15px !important;
  }
  #placeholdersection100 .firsttable tr, #placeholdersection100 .updates tr, #placeholdersection100 .sandbox tr, #placeholdersection100 .bandwidth tr, #placeholdersection100 .support tr, #placeholdersection100 .development tr, #placeholdersection100 .portal tr {
    height: 75px;
  }
  #placeholdersection100 .Backups tr {
    height: 90px;
  }
  #placeholdersection100 .homeboxproduct div .Item td, #placeholdersection100 .homeboxproduct .title td {
    font-size: 15px !important;
  }
}
page-footer {
  background-color: #fff;
  padding-top: 30px;
  text-align: center;
}

.page-footer.dark {
  background-color: #161e2e;
}

.page-footer .footer-copyright {
  background-color: #fff;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  margin-top: 50px;
  border: 1px solid #ededed;
}

.page-footer.dark .footer-copyright {
  background-color: #222425;
  border-color: #222425;
}

.page-footer .footer-copyright p {
  margin: 10px;
  color: #7d8288;
}

.page-footer.dark .footer-copyright p {
  color: #ed912a;
}

.page-footer ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

.page-footer h5 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.page-footer.dark h5 {
  color: #fff;
}

.page-footer a {
  color: #53595f;
  text-decoration: none;
}

.page-footer.dark a {
  color: #d2d1d1;
}

.page-footer a:focus, .page-footer a:hover {
  text-decoration: none;
  color: #1d2125;
}

.page-footer.dark a:focus, .page-footer.dark a:hover {
  color: #fff;
}

@media (min-width: 576px) {
  .page-footer {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .page-footer ul li {
    position: relative;
    padding-left: 10px;
  }
  .page-footer ul li:after, .page-footer ul li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 6px;
    border-radius: 2px;
    background-color: #ed912a;
    transform: rotate(-45deg);
  }
  .page-footer ul li:before {
    top: 9px;
    transform: rotate(-45deg);
  }
  .page-footer ul li:after {
    top: 13px;
    transform: rotate(45deg);
  }
}
body[data-path] #navbar11, body[data-path] #navbar00 {
  display: none !important;
}
body[data-path] .page-content-wrapper {
  padding-top: 68px;
}
body[data-path] #cleffooterinner {
  display: block;
}

body[data-path="pages/main"] #navbarinner {
  display: none !important;
}
body[data-path="pages/main"] #navbar00 {
  display: flex !important;
}
body[data-path="pages/main"] #navbar11 {
  display: table !important;
}
body[data-path="pages/main"] .page-content-wrapper {
  padding-top: 0;
}
body[data-path="pages/main"] #cleffooterinner {
  display: none;
}
body[data-path="pages/main"] #cleffooter {
  display: block;
}

body[data-path="pages/inner/youtube"] #navbarinner {
  display: none !important;
}
body[data-path="pages/inner/youtube"] #navbar00 {
  display: flex !important;
}
body[data-path="pages/inner/youtube"] #navbar11 {
  display: table !important;
}
body[data-path="pages/inner/youtube"] .page-content-wrapper {
  padding-top: 0;
}
body[data-path="pages/inner/youtube"] #cleffooterinner {
  display: block;
}

.swiper-button-next100, .swiper-button-prev100, .swiper-button-next1, .swiper-button-prev1 {
  color: rgb(247, 122, 5);
}

.form-control {
  background-color: #e8e4e1;
  color: #444444;
}

.form-control:focus {
  box-shadow: 0px 0px 0px 2px #e87821;
}

.clef-info {
  margin-bottom: 1rem;
}
.clef-info .svgcircle {
  border-radius: 50%;
  box-shadow: 0px 0 0 2px;
  color: #e87821;
  margin-right: 1rem;
}
.clef-info svg {
  height: 30px;
  width: 30px;
  fill: #e87821;
  color: #e87821;
  padding: 7px;
}
.clef-info a {
  color: #e87821;
}
.clef-info a:hover {
  color: #ebbc00;
  text-decoration: none;
}

.map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#page-login .btn {
  background-color: #e87821;
}
#page-login a {
  color: #e87821;
}
#page-login a:hover {
  color: #ebbc00;
}

.contact-content .btn {
  background-color: #e87821;
}
.contact-content .btn:active {
  background-color: #e87821 !important;
  border-color: #e87821 !important;
}
.contact-content .btn:active:focus {
  box-shadow: 0 0 0 0.2rem #e87821 !important;
}

#topimageyoutube {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
#topimageyoutube .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  z-index: 5;
}
#topimageyoutube .video {
  display: block;
}
#topimageyoutube .video video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  max-width: 100%;
  width: 100%;
  border: none;
  z-index: 10;
}

#youtube1 .video {
  aspect-ratio: 16/9;
  width: 100%;
}
#youtube1 iframe {
  border-width: 0;
  min-height: 350px;
  max-width: 100%;
  padding: 1rem;
}
#youtube1 .youtubetxt {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

#page-contact {
  background-image: url("/assets/clefincode/images/Oferta-de-lansare-a-noii-versiuni-SeniorERP-imagine-v1.jpg");
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

#navbar00 {
  opacity: 1;
  transition: opacity 1s;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: table;
}

.navbar {
  padding: 0;
}
.navbar .dropdown-menu a {
  color: #192734;
}
.navbar .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

#navbar11 {
  z-index: 100;
  height: 68px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: table;
  transition: top 0.3s;
}
#navbar11 .top0 .icon {
  stroke: #192734;
}
#navbar11 .top0 a {
  color: rgb(217, 217, 217) !important;
  font-weight: bold;
}
#navbar11 .top0 a:hover {
  color: #f9a02c !important;
}
#navbar11 .top0 li:hover {
  background-color: #333333 !important;
}
#navbar11 .top0 .show li:hover {
  background-color: transparent !important;
}
#navbar11 li:hover {
  background-color: rgba(0, 0, 0, 0.49);
}
#navbar11 a {
  color: white;
  font-weight: bold;
}
#navbar11 a:hover {
  color: white;
}
#navbar11 .show li:hover {
  background-color: transparent;
}
#navbar11 .navbar-toggler {
  background-color: #d9d9d9;
}
#navbar11 .icon {
  stroke: white;
}

#navbarinner {
  z-index: 100;
  height: 68px;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 100;
  display: table;
  transition: top 0.3s;
  background-color: #161e2e !important;
}
#navbarinner .top0 .icon {
  stroke: #192734;
}
#navbarinner .top0 a {
  color: rgb(217, 217, 217) !important;
  font-weight: bold;
}
#navbarinner .top0 a:hover {
  color: #f9a02c !important;
}
#navbarinner .top0 li:hover {
  background-color: #333333 !important;
}
#navbarinner .top0 .show li:hover {
  background-color: transparent !important;
}
#navbarinner .top0 .ml-auto .nav-item:hover {
  background-color: #131e2e !important;
}
#navbarinner li:hover {
  background-color: #333333;
}
#navbarinner a {
  color: white;
  font-weight: bold;
}
#navbarinner a:hover {
  color: white;
}
#navbarinner .show li:hover {
  background-color: transparent;
}
#navbarinner .navbar-toggler {
  background-color: #d9d9d9;
}
#navbarinner .icon {
  stroke: white;
}

#cleffooterinner {
  background-color: rgb(51, 51, 51);
}
#cleffooterinner .container {
  padding: 50px;
}
#cleffooterinner .four {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#cleffooterinner .counter-box {
  display: block;
  padding: 20px;
  text-align: center;
}
#cleffooterinner .counter-box p {
  margin: 5px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
}
#cleffooterinner .counter-box span {
  font-size: 60px;
  margin: 0 0 15px;
  color: #ffffff;
}
#cleffooterinner .counter {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
  line-height: 28px;
}
#cleffooterinner .lght {
  color: #ffffff;
}
#cleffooterinner .drk {
  color: #ffffff;
}
#cleffooterinner #counters1 {
  background-color: #e87821;
}
#cleffooterinner #counters2 {
  background-color: #fd7100;
}
#cleffooterinner #counters1 span, #cleffooterinner #counters2 span {
  color: #ffffff !important;
}
#cleffooterinner #counters1 p, #cleffooterinner #counters2 p {
  color: #ffffff;
}

#clefincode h1 {
  color: #453d3d;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 30px;
}
#clefincode .icon_container {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #e87821;
  border-radius: 50%;
  padding: 6px;
  background-color: transparent;
}
#clefincode .icon_container svg {
  fill: #e87821 !important;
  height: 16px;
  width: 16px;
}
#clefincode .icon_container i {
  fill: #e87821 !important;
  color: #e87821 !important;
  height: 16px;
  width: 16px;
}
#clefincode .icon_container0 {
  justify-content: center;
  align-items: center;
  border: 2px solid #e87821;
  border-radius: 50%;
  padding: 6px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
#clefincode .icon_container0 svg {
  fill: #e87821 !important;
  height: 16px;
  width: 16px;
}
#clefincode .icon_container0 i {
  fill: #e87821 !important;
  color: #e87821 !important;
  height: 16px;
  width: 16px;
}
#clefincode .icon_container1 {
  height: 36px;
  width: 36px;
}
#clefincode .icon_container:hover {
  background-color: #e87821;
}
#clefincode .icon_container:hover svg {
  fill: #ffffff !important;
}
#clefincode .clef-btn {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-size: 17px !important;
  padding: 15px 25px 15px 25px !important;
  line-height: 1.5 !important;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0em;
  border: 2px solid;
  border-radius: 0.3em;
  padding: 0.8em 1.8em;
  background: transparent;
  border-color: #e87821;
  color: #ffffff !important;
  outline: none !important;
  text-decoration: none;
}
#clefincode .clef-btn i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  transition: height 0.5s;
  background: #e87821;
  z-index: -1;
}
#clefincode .clef-btn svg {
  height: 16px;
  fill: white;
}
#clefincode .clef-btn:hover {
  text-decoration: none;
}
#clefincode .clef-btn:hover i:after {
  height: 100%;
}
#clefincode .topimage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
#clefincode .topimage .image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  z-index: 5;
}
#clefincode .topimage .video {
  display: block;
}
#clefincode .topimage .video video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  max-width: 100%;
  width: 100%;
  border: none;
  z-index: 10;
}
#clefincode #row-cards .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}
#clefincode #row-cards .cardbox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border-color: inherit;
  padding: 75px 20px 75px 80px !important;
  min-height: 350px;
}
#clefincode #row-cards .iconbox svg {
  width: 65px;
  height: 70px;
}
#clefincode #row-cards .card1 {
  background: #e87821 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card1 h4 {
  color: #ffffff !important;
}
#clefincode #row-cards .card2 {
  background: #ffffff !important;
  color: #453d3d !important;
}
#clefincode #row-cards .card2 h4 {
  color: #453d3d !important;
}
#clefincode #row-cards .card3 {
  background: #eb8c42 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card3 h4 {
  color: #ffffff !important;
}
#clefincode #row-cards .card4 {
  background: #444444 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card4 h4 {
  color: #ffffff !important;
}
#clefincode #counters {
  background-color: #e8e4e1;
}
#clefincode #counters .container {
  padding: 50px;
}
#clefincode #counters .four {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#clefincode #counters .counter-box {
  display: block;
  padding: 20px;
  text-align: center;
}
#clefincode #counters .counter-box p {
  margin: 5px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
}
#clefincode #counters .counter-box span {
  font-size: 60px;
  margin: 0 0 15px;
  color: #453d3d;
}
#clefincode #counters .counter {
  font-size: 32px;
  font-weight: 700;
  color: #453d3d;
  line-height: 28px;
}
#clefincode #counters .lght {
  color: #453d3d;
}
#clefincode #counters .drk {
  color: #000000;
}
#clefincode #counters #counters2 span, #clefincode #counters #counters4 span {
  color: #e87821 !important;
}
#clefincode #counters #counters2 p, #clefincode #counters #counters4 p {
  color: #453d3d;
}
#clefincode #fixed_bg {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
#clefincode #fixed_bg .imagebg {
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
}
#clefincode #fixed_bg .bgoverlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#clefincode #fixed_bg .content1 {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  color: #ffffff;
}
#clefincode #fixed_bg .content1 h1 {
  color: #ffffff;
}
#clefincode #fixed_bg .content1 .clef_row {
  margin: 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
}
@keyframes afl {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#clefincode #fixed_bg .content1 .us_animate_afl {
  width: 50%;
  position: relative;
  opacity: 0;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  border-color: #e8e4e1;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  float: left;
}
#clefincode #fixed_bg .content1 .start {
  animation-name: afl;
}
#clefincode #two-sides {
  max-width: 1290px;
  margin: auto;
}
#clefincode #two-sides .leftimage .leftimage-image {
  display: block;
  width: 50%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#clefincode #two-sides .leftimage .leftimage-text {
  width: 50%;
}
#clefincode #two-sides .rightimage {
  background-color: #e8e4e1;
}
#clefincode #two-sides .rightimage .rightimage-text {
  width: 50%;
}
#clefincode #two-sides .rightimage .rightimage-image {
  width: 50%;
  display: block;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#clefincode #overlaytext {
  position: relative;
}
#clefincode #overlaytext .imagebg1 {
  background-position: center center;
  background-size: cover;
  min-height: calc(100vh - 65px);
}
#clefincode #overlaytext .imagebg1 #imagebg11 {
  padding-top: 4rem;
  padding-right: 2rem;
}
#clefincode #overlaytext .imagebg1 .bgwhite {
  background-color: #ffffff;
  width: 60%;
  float: right;
  padding: 30px;
}
#clefincode #overlaytext .imagebg1 .bgwhite a {
  color: #e87821 !important;
  display: inline-block;
}
#clefincode #overlaytext .imagebg1 .bgwhite svg {
  height: 16px;
  fill: #e87821;
}
#clefincode #overlaytext .imagebg1 .bgwhite a:hover {
  color: #ffffff !important;
}
#clefincode #overlaytext .imagebg1 .bgwhite a:hover svg {
  height: 16px;
  fill: #ffffff !important;
}
@keyframes afl1 {
  from {
    opacity: 0;
    transform: translateX(3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#clefincode #overlaytext .imagebg1 .us_animate_afl1 {
  position: relative;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#clefincode #overlaytext .imagebg1 .start1 {
  animation-name: afl1;
}
#clefincode #testimonial .row {
  display: flex;
  width: 100%;
}
#clefincode #testimonial h2 {
  color: #000;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 30px;
}
#clefincode #testimonial h2:after {
  content: "";
  width: 100px;
  position: absolute;
  margin: 0 auto;
  height: 4px;
  border-radius: 1px;
  background: #f77a05 !important;
  left: 0;
  right: 0;
  bottom: -20px;
}
#clefincode #testimonial .carousel .carousel-item {
  color: #999;
  overflow: hidden;
  min-height: 120px;
  font-size: 13px;
}
#clefincode #testimonial .carousel .media img {
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 50%;
}
#clefincode #testimonial .carousel .testimonial {
  padding: 0 15px 0 60px;
  position: relative;
}
#clefincode #testimonial .carousel .testimonial:before {
  content: "“";
  font-family: Arial, sans-serif;
  color: #f77a05;
  font-weight: bold;
  font-size: 68px;
  line-height: 54px;
  position: absolute;
  left: 15px;
  top: 0;
}
#clefincode #testimonial .carousel .overview b {
  text-transform: uppercase;
  color: #f77a05;
}
#clefincode #testimonial .carousel .carousel-indicators {
  bottom: -40px;
}
#clefincode #testimonial .carousel .carousel-indicators li, #clefincode #testimonial .carousel .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 1px 3px;
  box-sizing: border-box;
}
#clefincode #testimonial .carousel .carousel-indicators li {
  background: #ff7200;
  border: 4px solid #fff;
}
#clefincode #testimonial .carousel .carousel-indicators li .active {
  color: #fff;
  background: #f77a05;
  border: 5px double;
}
#clefincode #fixed_bg1 {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
#clefincode #fixed_bg1 .imagebg {
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
}
#clefincode #fixed_bg1 .bgoverlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#clefincode #fixed_bg1 .content1 {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  color: #ffffff;
}
#clefincode #fixed_bg1 .content1 h1 {
  color: #ffffff;
}
#clefincode #fixed_bg1 .content1 #clef_rowhidden {
  width: 50%;
}
#clefincode #fixed_bg1 .content1 .clef_row {
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}
#clefincode #fixed_bg1 .content1 .clef_hr_inner h4 {
  color: white;
  text-align: center;
}
#clefincode #fixed_bg1 .content1 .clef_hr_inner .clef_hr_inner1 {
  min-height: 150px;
}
#clefincode #fixed_bg1 .content1 .clef_hr_inner svg {
  fill: #f77a05 !important;
}
@keyframes afl2 {
  from {
    opacity: 0;
    transform: translateX(3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#clefincode #fixed_bg1 .content1 .us_animate_afl2 {
  width: 100%;
  position: relative;
  opacity: 0;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  border-color: #e8e4e1;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  float: left;
}
#clefincode #fixed_bg1 .content1 .start2 {
  animation-name: afl2;
}
#clefincode #systems {
  margin-bottom: 26px;
}
#clefincode #systems h2 {
  margin-top: 1rem !important;
}
#clefincode #systems .clef-grid-item {
  position: relative;
  overflow: hidden;
  padding: 2rem;
  min-height: 100%;
  flex: 1;
  cursor: pointer;
}
#clefincode #systems .grid-item-bg {
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
  transition-duration: 0.4s;
  transform-origin: 50% 50%;
  transform: scale(1) translate(0, 0);
}
#clefincode #systems .post_title {
  font-size: 14px !important;
  font-weight: 700 !important;
}
#clefincode #systems .grid12, #clefincode #systems .grid3, #clefincode #systems .grid4, #clefincode #systems .grid56, #clefincode #systems .grid78, #clefincode #systems .grid9, #clefincode #systems .grid10, #clefincode #systems .grid1112 {
  flex: 1;
}
#clefincode #systems .grid12 li, #clefincode #systems .grid3 li, #clefincode #systems .grid4 li, #clefincode #systems .grid56 li, #clefincode #systems .grid78 li, #clefincode #systems .grid9 li, #clefincode #systems .grid10 li, #clefincode #systems .grid1112 li {
  font-size: 12px !important;
  font-weight: 400 !important;
}
#clefincode #systems .grid123 {
  width: 50%;
}
#clefincode #systems .grid456 {
  width: 50%;
}
#clefincode #systems .grid789 {
  width: 50%;
}
#clefincode #systems .grid101112 {
  width: 50%;
}
#clefincode #systems .grid-item-bg1 {
  background-image: url("/assets/clefincode/images/discover-our-systems/1.jpg");
}
#clefincode #systems .grid-item-bg2 {
  background-image: url("/assets/clefincode/images/discover-our-systems/2.jpg");
}
#clefincode #systems .grid-item-bg3 {
  background-image: url("/assets/clefincode/images/discover-our-systems/3.jpg");
}
#clefincode #systems .grid-item-bg4 {
  background-image: url("/assets/clefincode/images/discover-our-systems/4.png");
}
#clefincode #systems .grid-item-bg5 {
  background-image: url("/assets/clefincode/images/discover-our-systems/5.jpg");
}
#clefincode #systems .grid-item-bg6 {
  background-image: url("/assets/clefincode/images/discover-our-systems/6.jpg");
}
#clefincode #systems .grid-item-bg7 {
  background-image: url("/assets/clefincode/images/discover-our-systems/7.jpg");
}
#clefincode #systems .grid-item-bg8 {
  background-image: url("/assets/clefincode/images/discover-our-systems/8.jpg");
}
#clefincode #systems .grid-item-bg9 {
  background-image: url("/assets/clefincode/images/discover-our-systems/9.jpg");
}
#clefincode #systems .grid-item-bg10 {
  background-image: url("/assets/clefincode/images/discover-our-systems/10.png");
}
#clefincode #systems .grid-item-bg11 {
  background-image: url("/assets/clefincode/images/discover-our-systems/11.jpg");
}
#clefincode #systems .grid-item-bg12 {
  background-image: url("/assets/clefincode/images/discover-our-systems/12.jpg");
}
#clefincode #systems .grid-item-content {
  position: relative;
  transition-duration: 0.3s;
  transform-origin: 50% 50%;
  transform: scale(0) translate(0, 0);
  opacity: 0;
  background-color: white;
  padding: 2rem;
}
#clefincode #systems .grid-item-content-large {
  padding: 4rem;
}
#clefincode #systems .clef-grid-item:hover .grid-item-bg {
  transform: scale(1.2) translate(0, 0);
  opacity: 1;
}
#clefincode #systems .clef-grid-item:hover .grid-item-content {
  transform: scale(1) translate(0, 0);
  opacity: 0.95;
}
#clefincode #fixed_bg2 {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
#clefincode #fixed_bg2 .imagebg {
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-size: cover;
}
#clefincode #fixed_bg2 .bgoverlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#clefincode #fixed_bg2 .content1 {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  color: #ffffff;
}
#clefincode #fixed_bg2 .content1 h1 {
  color: #ffffff;
}
#clefincode #fixed_bg2 .content1 h4 {
  color: #ffffff !important;
}
#clefincode #fixed_bg2 .content1 #clef_rowhidden {
  width: 50%;
}
#clefincode #fixed_bg2 .content1 .clef_row {
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}
#clefincode #fixed_bg2 .content1 .clef_hr_inner3 {
  animation-duration: 1.1s;
  animation-fill-mode: both;
}
#clefincode #fixed_bg2 .content1 .clef_hr_inner3 h4 {
  color: white;
  text-align: center;
}
#clefincode #fixed_bg2 .content1 .clef_hr_inner3 .clef_hr_inner1 {
  min-height: 150px;
}
#clefincode #fixed_bg2 .content1 .clef_hr_inner3 svg {
  fill: #f77a05 !important;
}
#clefincode #fixed_bg2 .content1 .clef_hr_inner3 i {
  fill: #f77a05 !important;
}
@keyframes afl3 {
  from {
    opacity: 0;
    transform: translateY(-8rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes afl33 {
  from {
    opacity: 0;
    transform: translateX(-8rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#clefincode #fixed_bg2 .content1 .us_animate_afl3 {
  width: 100%;
  position: relative;
  opacity: 0;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  border-color: #e8e4e1;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  float: left;
}
#clefincode #fixed_bg2 .content1 .start3 {
  animation-name: afl3;
}
#clefincode #fixed_bg2 .content1 .start33 {
  animation-name: afl33;
}
#clefincode #usefularticals .usefulcard {
  width: 100%;
}
#clefincode #usefularticals h1 {
  color: #453d3d;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 30px;
}
#clefincode #usefularticals .utitle a {
  color: #e87821;
  font-size: 14px;
  font-weight: 700;
}
#clefincode #usefularticals .utitle a:hover {
  color: #e87821;
  text-decoration: none;
}
#clefincode #usefularticals .bg-color {
  background: #fce8d2;
  width: 120%;
  position: relative;
  z-index: 2;
}
#clefincode #usefularticals .bg-color a {
  color: rgb(69, 61, 61);
}
#clefincode #usefularticals .bg-color a:hover {
  color: #e87821;
  text-decoration: none;
}
#clefincode #usefularticals .imagecard {
  z-index: 1;
}
#clefincode #usefularticals .imagecard img {
  height: 100%;
  width: 100%;
  max-height: 350px;
  max-width: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}
#clefincode #shadowbox .shadowhover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
}
#clefincode #shadowbox .shadowhover:hover {
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.3490196078);
}
#clefincode #shadowbox .lefthalf {
  width: 50%;
  overflow: hidden;
  perspective: 1px;
  border-radius: 5px 0px 0px 5px !important;
}
#clefincode #shadowbox .lefthalf a {
  color: #e87821 !important;
  display: inline-block;
}
#clefincode #shadowbox .lefthalf a:hover {
  color: #ffffff !important;
}
#clefincode #shadowbox .lefthalf a:hover svg {
  height: 16px;
  fill: #ffffff !important;
}
#clefincode #shadowbox .lefthalf svg {
  height: 16px;
  fill: #e87821 !important;
}
#clefincode #shadowbox .righthalf {
  overflow: hidden;
  perspective: 1px;
  border-radius: 0px 5px 5px 0px !important;
  width: 50%;
  border-color: rgba(68, 68, 68, 0.4) !important;
}
#clefincode #shadowbox .infobox11 {
  background: #e87821;
  margin-top: 16px;
  border-radius: 5px;
  color: #ffffff;
  width: 50%;
}
#clefincode #shadowbox .infobox12 {
  background: #eb8c42;
  margin-top: 16px;
  border-radius: 5px;
  color: #ffffff;
  width: 50%;
}
#clefincode #casestudy {
  background: rgb(245, 245, 245);
  padding: 16px;
}
#clefincode #casestudy h1 {
  color: #453d3d;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 30px;
}
#clefincode #casestudy img {
  height: 100%;
  width: 100%;
  max-height: 100px;
  max-width: 120px;
  -o-object-fit: cover;
     object-fit: cover;
}
#clefincode #casestudy .case0 {
  padding: 36px;
}
#clefincode #casestudy .bgw {
  padding: 36px;
  background-color: #ffffff;
}
#clefincode #casestudy .bgw a {
  color: #e87821;
}
#clefincode #casestudy .bgw a:hover {
  color: #e87821;
  text-decoration: none;
}
#clefincode #casestudy .case0, #clefincode #casestudy .case {
  z-index: 2;
}
#clefincode #casestudy .youtube {
  z-index: 1;
}
#clefincode #casestudy .youtube a:hover {
  text-decoration: none;
}
#clefincode #casestudy .youtubeinner {
  width: auto;
  height: 400px;
  min-width: 300px;
  background: url(https://www.seniorsoftware.ro/wp-content/uploads/2021/03/studiu-de-caz-video-terasteel-managementul-productiei-aps-mes-software-productie-v2.jpg) no-repeat center center/cover !important;
}
#clefincode #casestudy .case2 {
  padding-left: 24px;
}
#clefincode #casestudy .case1 {
  padding-bottom: 16px;
}
#clefincode #casestudy .case1 a {
  color: rgb(69, 61, 61);
}
#clefincode #casestudy .case1 a:hover {
  color: rgb(69, 61, 61);
  text-decoration: none;
}
#clefincode #casestudy .caseinner1 {
  margin-left: 16px;
}
#clefincode #testcarousel {
  /*MD*/
  /*LG */
  /*LG 6th  -  if you want a carousel with 6 slides */
}
#clefincode #testcarousel h1 {
  color: #453d3d;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 30px 0 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  #clefincode #testcarousel {
    /* Show 4th slide on md  if col-md-4*/
  }
  #clefincode #testcarousel .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  #clefincode #testcarousel {
    /* Show 3rd slide on sm  if col-sm-6*/
  }
  #clefincode #testcarousel .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 576px) {
  #clefincode #testcarousel {
    /* show 2 items */
    /* left or forward direction */
    /* farthest right hidden item must be abso position for animations */
    /* right or prev direction */
  }
  #clefincode #testcarousel .carousel-item {
    margin-right: 0;
  }
  #clefincode #testcarousel .carousel-inner .active + .carousel-item {
    display: block;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  #clefincode #testcarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  #clefincode #testcarousel .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  #clefincode #testcarousel .carousel-item-next.carousel-item-left + .carousel-item,
  #clefincode #testcarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  #clefincode #testcarousel .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  #clefincode #testcarousel .carousel-item-prev.carousel-item-right + .carousel-item,
  #clefincode #testcarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  #clefincode #testcarousel {
    /* show 3rd of 3 item slide */
    /* left or forward direction */
    /* right or prev direction */
  }
  #clefincode #testcarousel .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  #clefincode #testcarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #clefincode #testcarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 991px) {
  #clefincode #testcarousel {
    /* show 4th item */
    /* Show 5th slide on lg if col-lg-3 */
    /* left or forward direction */
    /* right or prev direction //t - previous slide direction last item animation fix */
  }
  #clefincode #testcarousel .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }
  #clefincode #testcarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }
  #clefincode #testcarousel .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
  #clefincode #testcarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #clefincode #testcarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 991px) {
  #clefincode #testcarousel {
    /* show 5th and 6th item */
    /*   .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
        .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
            display: block;
        }



        .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
        .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
            transition: none;
        }
    */
    /*show 7th slide for animation when its a 6 slides carousel */
    /*      .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item  + .carousel-item {
            position: absolute;
            top: 0;
            right: -16.666666666%;
            z-index: -1;
            display: block;
            visibility: visible;
        }
        */
    /* forward direction > */
    /*   .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
        .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
            position: relative;
            transform: translate3d(-100%, 0, 0);
            visibility: visible;
        }
        */
    /* prev direction < last item animation fix */
    /*   .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
        .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
            position: relative;
            transform: translate3d(100%, 0, 0);
            visibility: visible;
            display: block;
            visibility: visible;
        }
    */
  }
}

.wid-50 {
  width: 100%;
}

#topimageyoutube .video {
  display: none;
}

#clefincode .topimage .video {
  display: none;
}
#clefincode #row-cards .cardbox {
  padding: 8px !important;
  min-height: 360px;
}
#clefincode #row-cards .card1 {
  background: #e87821 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card1 h4 {
  color: #ffffff !important;
}
#clefincode #row-cards .card2 {
  background: #ffffff !important;
  color: #453d3d !important;
}
#clefincode #row-cards .card2 h4 {
  color: #453d3d !important;
}
#clefincode #row-cards .card2 svg {
  stroke: #444444;
}
#clefincode #row-cards .card3 {
  background: #eb8c42 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card3 h4 {
  color: #ffffff !important;
}
#clefincode #row-cards .card3 svg {
  stroke: #444444;
}
#clefincode #row-cards .card4 {
  background: #444444 !important;
  color: #ffffff !important;
}
#clefincode #row-cards .card4 h4 {
  color: #ffffff !important;
}
#clefincode #counters .counter-box {
  padding: 20px;
}
#clefincode #counters .col-sm-3 {
  padding-left: 15px;
  padding-right: 15px;
}
#clefincode #fixed_bg .content1 .clef_row {
  margin: 0 1rem;
}
#clefincode #fixed_bg .content1 .us_animate_afl {
  width: 100% !important;
  opacity: 1;
}
#clefincode #two-sides .leftimage .leftimage-image {
  display: none !important;
}
#clefincode #two-sides .leftimage .leftimage-text {
  width: 100% !important;
}
#clefincode #two-sides .rightimage .rightimage-text {
  width: 100%;
}
#clefincode #two-sides .rightimage .rightimage-image {
  display: none !important;
}
#clefincode #overlaytext .imagebg1 {
  background-image: none;
  height: 0;
  min-height: 0;
}
#clefincode #overlaytext .imagebg1 #imagebg11 {
  padding-top: 0;
  padding-right: 0;
  padding: 1rem;
}
#clefincode #overlaytext .imagebg1 .bgwhite {
  width: 100% !important;
}
#clefincode #overlaytext .imagebg1 .us_animate_afl1 {
  opacity: 1;
}
#clefincode #fixed_bg1 .content1 #clef_rowhidden {
  width: 0 !important;
}
#clefincode #fixed_bg1 .content1 .us_animate_afl2 {
  opacity: 1;
}
#clefincode #shadowbox .lefthalf {
  width: 100% !important;
  border-radius: 5px 5px 5px 5px !important;
}
#clefincode #shadowbox .righthalf {
  width: 0 !important;
}
#clefincode #shadowbox .infobox11, #clefincode #shadowbox .infobox12 {
  width: 100%;
}
#clefincode #systems ul {
  padding-left: 15px;
}
#clefincode #systems .grid-item-content-large {
  padding: 1rem;
}
#clefincode #systems .grid-item-content {
  padding: 0.5rem;
}
#clefincode #systems .clef-grid-item {
  padding: 0.5rem;
}
#clefincode #systems .grid123 {
  width: 100% !important;
}
#clefincode #systems .grid456 {
  width: 100% !important;
}
#clefincode #systems .grid789 {
  width: 100% !important;
}
#clefincode #systems .grid101112 {
  width: 100% !important;
}
#clefincode #fixed_bg2 .content1 .us_animate_afl3 {
  opacity: 1;
}
#clefincode #usefularticals .container {
  max-width: 540px;
}
#clefincode #usefularticals .usefulcard {
  width: 100%;
}
#clefincode #casestudy .bgw {
  width: 100%;
}
#clefincode #casestudy #youtube {
  display: none;
}
#clefincode #casestudy .case {
  width: 100%;
}

@media (min-width: 576px) {
  .wid-50 {
    width: 100%;
  }
  #topimageyoutube .video {
    display: none;
  }
  #clefincode .topimage .video {
    display: none;
  }
  #clefincode #row-cards .cardbox {
    padding: 18px !important;
    min-height: 500px;
  }
  #clefincode #row-cards .card1 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card1 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 svg {
    stroke: white;
  }
  #clefincode #row-cards .card3 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 svg {
    stroke: white;
  }
  #clefincode #row-cards .card4 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card4 h4 {
    color: #ffffff !important;
  }
  #clefincode #counters .counter-box {
    padding: 10px;
  }
  #clefincode #counters .col-sm-3 {
    padding-left: 5px;
    padding-right: 5px;
  }
  #clefincode #fixed_bg .content1 .clef_row {
    margin: 0 1rem;
  }
  #clefincode #fixed_bg .content1 .us_animate_afl {
    width: 100% !important;
    opacity: 1;
  }
  #clefincode #two-sides .leftimage .leftimage-image {
    display: none !important;
  }
  #clefincode #two-sides .leftimage .leftimage-text {
    width: 100% !important;
  }
  #clefincode #two-sides .rightimage .rightimage-text {
    width: 100%;
  }
  #clefincode #two-sides .rightimage .rightimage-image {
    display: none !important;
  }
  #clefincode #overlaytext .imagebg1 {
    background-image: none;
    min-height: 0;
    height: 0;
  }
  #clefincode #overlaytext .imagebg1 #imagebg11 {
    padding-top: 0;
    padding-right: 0;
    padding: 1rem;
  }
  #clefincode #overlaytext .imagebg1 .bgwhite {
    width: 100% !important;
  }
  #clefincode #overlaytext .imagebg1 .us_animate_afl1 {
    opacity: 1;
  }
  #clefincode #fixed_bg1 .content1 #clef_rowhidden {
    width: 0 !important;
  }
  #clefincode #fixed_bg1 .content1 .us_animate_afl2 {
    opacity: 1;
  }
  #clefincode #shadowbox .lefthalf {
    width: 100% !important;
    border-radius: 5px 5px 5px 5px !important;
  }
  #clefincode #shadowbox .righthalf {
    width: 0 !important;
  }
  #clefincode #shadowbox .infobox11, #clefincode #shadowbox .infobox12 {
    width: 100%;
  }
  #clefincode #systems ul {
    padding-left: 15px;
  }
  #clefincode #systems .grid-item-content-large {
    padding: 2rem;
  }
  #clefincode #systems .grid-item-content {
    padding: 1rem;
  }
  #clefincode #systems .clef-grid-item {
    padding: 1rem;
  }
  #clefincode #systems .grid123 {
    width: 100% !important;
  }
  #clefincode #systems .grid456 {
    width: 100% !important;
  }
  #clefincode #systems .grid789 {
    width: 100% !important;
  }
  #clefincode #systems .grid101112 {
    width: 100% !important;
  }
  #clefincode #fixed_bg2 .content1 .us_animate_afl3 {
    opacity: 1;
  }
  #clefincode #usefularticals .container {
    max-width: 100%;
  }
  #clefincode #usefularticals .usefulcard {
    width: 100%;
  }
  #clefincode #casestudy .bgw {
    width: 100%;
  }
  #clefincode #casestudy #youtube {
    display: none;
  }
  #clefincode #casestudy .case {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wid-50 {
    width: 100%;
  }
  #topimageyoutube .video {
    display: block;
  }
  #clefincode .topimage .video {
    display: block;
  }
  #clefincode #row-cards .cardbox {
    padding: 26px !important;
    min-height: 500px;
  }
  #clefincode #row-cards .card1 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card1 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 svg {
    stroke: white;
  }
  #clefincode #row-cards .card3 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 svg {
    stroke: white;
  }
  #clefincode #row-cards .card4 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card4 h4 {
    color: #ffffff !important;
  }
  #clefincode #counters .counter-box {
    padding: 10px;
  }
  #clefincode #counters .col-sm-3 {
    padding-left: 10x;
    padding-right: 10px;
  }
  #clefincode #fixed_bg .content1 .clef_row {
    margin: 0 1.5rem;
  }
  #clefincode #fixed_bg .content1 .us_animate_afl {
    width: 100% !important;
    opacity: 1;
  }
  #clefincode #two-sides .leftimage .leftimage-image {
    display: none !important;
  }
  #clefincode #two-sides .leftimage .leftimage-text {
    width: 100% !important;
  }
  #clefincode #two-sides .rightimage .rightimage-text {
    width: 100% !important;
  }
  #clefincode #two-sides .rightimage .rightimage-image {
    display: none !important;
  }
  #clefincode #overlaytext .imagebg1 {
    min-height: calc(100vh - 65px);
  }
  #clefincode #overlaytext .imagebg1 #imagebg11 {
    padding: 2rem;
    padding-top: 2rem;
    padding-right: 2rem;
  }
  #clefincode #overlaytext .imagebg1 .bgwhite {
    width: 60% !important;
  }
  #clefincode #overlaytext .imagebg1 .us_animate_afl1 {
    opacity: 1;
  }
  #clefincode #fixed_bg1 .content1 #clef_rowhidden {
    width: 0 !important;
  }
  #clefincode #fixed_bg1 .content1 .us_animate_afl2 {
    opacity: 1;
  }
  #clefincode #shadowbox .lefthalf {
    width: 50% !important;
    border-radius: 5px 0px 0px 5px !important;
  }
  #clefincode #shadowbox .righthalf {
    width: 50% !important;
  }
  #clefincode #shadowbox .infobox11, #clefincode #shadowbox .infobox12 {
    width: 50%;
  }
  #clefincode #systems ul {
    padding-left: 20px;
  }
  #clefincode #systems .grid-item-content-large {
    padding: 2rem;
  }
  #clefincode #systems .grid-item-content {
    padding: 1rem;
  }
  #clefincode #systems .clef-grid-item {
    padding: 1rem;
  }
  #clefincode #systems .grid123 {
    width: 100% !important;
  }
  #clefincode #systems .grid456 {
    width: 100% !important;
  }
  #clefincode #systems .grid789 {
    width: 100% !important;
  }
  #clefincode #systems .grid101112 {
    width: 100% !important;
  }
  #clefincode #fixed_bg2 .content1 .us_animate_afl3 {
    opacity: 1;
  }
  #clefincode #usefularticals .container {
    max-width: 840px;
  }
  #clefincode #usefularticals .usefulcard {
    width: 100%;
  }
  #clefincode #casestudy .bgw {
    width: 100%;
  }
  #clefincode #casestudy #youtube {
    display: none;
  }
  #clefincode #casestudy .case {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .wid-50 {
    width: 50%;
  }
  #clefincode #row-cards .cardbox {
    padding: 37.5px 30px 37.5px 25px !important;
    min-height: 350px;
  }
  #clefincode #row-cards .card1 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card1 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 {
    background: #ffffff !important;
    color: #453d3d !important;
  }
  #clefincode #row-cards .card2 h4 {
    color: #453d3d !important;
  }
  #clefincode #row-cards .card2 svg {
    stroke: #444444;
  }
  #clefincode #row-cards .card3 {
    background: #eb8c42 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 svg {
    stroke: #444444;
  }
  #clefincode #row-cards .card4 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card4 h4 {
    color: #ffffff !important;
  }
  #clefincode #counters .counter-box {
    padding: 20px;
  }
  #clefincode #counters .col-sm-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  #clefincode #fixed_bg .content1 .clef_row {
    margin: 0 1.5rem;
  }
  #clefincode #fixed_bg .content1 .us_animate_afl {
    width: 80% !important;
    opacity: 0;
  }
  #clefincode #two-sides .leftimage .leftimage-image {
    display: block !important;
  }
  #clefincode #two-sides .leftimage .leftimage-text {
    width: 50% !important;
  }
  #clefincode #two-sides .rightimage .rightimage-text {
    width: 50% !important;
  }
  #clefincode #two-sides .rightimage .rightimage-image {
    display: block !important;
  }
  #clefincode #overlaytext .imagebg1 #imagebg11 {
    padding: 0;
    padding-top: 4rem;
    padding-right: 2rem;
  }
  #clefincode #overlaytext .imagebg1 .bgwhite {
    width: 60% !important;
  }
  #clefincode #overlaytext .imagebg1 .us_animate_afl1 {
    opacity: 0;
  }
  #clefincode #fixed_bg1 .content1 #clef_rowhidden {
    width: 50% !important;
  }
  #clefincode #fixed_bg1 .content1 .us_animate_afl2 {
    opacity: 0;
  }
  #clefincode #shadowbox .lefthalf {
    width: 50% !important;
  }
  #clefincode #shadowbox .righthalf {
    width: 50% !important;
  }
  #clefincode #systems ul {
    padding-left: 40px;
  }
  #clefincode #systems .grid-item-content-large {
    padding: 4rem;
  }
  #clefincode #systems .grid-item-content {
    padding: 2rem;
  }
  #clefincode #systems .clef-grid-item {
    padding: 2rem;
  }
  #clefincode #systems .grid123 {
    width: 50% !important;
  }
  #clefincode #systems .grid456 {
    width: 50% !important;
  }
  #clefincode #systems .grid789 {
    width: 50% !important;
  }
  #clefincode #systems .grid101112 {
    width: 50% !important;
  }
  #clefincode #fixed_bg2 .content1 .us_animate_afl3 {
    opacity: 0;
  }
  #clefincode #usefularticals .container {
    max-width: 1090px;
  }
  #clefincode #usefularticals .usefulcard {
    width: 50%;
  }
  #clefincode #casestudy .bgw {
    width: 125%;
  }
  #clefincode #casestudy #youtube {
    display: block;
  }
  #clefincode #casestudy .case {
    width: 33.3%;
  }
}
@media (min-width: 1200px) {
  #clefincode #row-cards .cardbox {
    padding: 75px 60px 75px 55px !important;
    min-height: 350px;
  }
  #clefincode #row-cards .card1 {
    background: #e87821 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card1 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card2 {
    background: #ffffff !important;
    color: #453d3d !important;
  }
  #clefincode #row-cards .card2 h4 {
    color: #453d3d !important;
  }
  #clefincode #row-cards .card3 {
    background: #eb8c42 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card3 h4 {
    color: #ffffff !important;
  }
  #clefincode #row-cards .card4 {
    background: #444444 !important;
    color: #ffffff !important;
  }
  #clefincode #row-cards .card4 h4 {
    color: #ffffff !important;
  }
  #clefincode #counters .counter-box {
    padding: 20px;
  }
  #clefincode #fixed_bg .content1 .clef_row {
    margin: 0 1.5rem;
  }
  #clefincode #fixed_bg .content1 .us_animate_afl {
    width: 80% !important;
    opacity: 0;
  }
  #clefincode #overlaytext .imagebg1 .us_animate_afl1 {
    opacity: 0;
  }
  #clefincode #fixed_bg1 .content1 .us_animate_afl2 {
    opacity: 0;
  }
  #clefincode #fixed_bg2 .content1 .us_animate_afl3 {
    opacity: 0;
  }
  #clefincode #usefularticals .container {
    max-width: 1290px;
  }
  #clefincode #usefularticals .usefulcard {
    width: 50%;
  }
}
* {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.padding-top > div[id^=page-]:not([data-path="pages/main"]) {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.padding-top > div[id^=page-]:not(:not([data-path=all-products])) {
  margin-bottom: 1rem;
}
.padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.padding-top > div[id=page-youtube] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.navbar-ours {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: table;
  transition: height 1s;
  background-color: #161e2e !important;
}
.navbar-ours .navbar-brand {
  color: #ed912a !important;
}
.navbar-ours .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
}
.navbar-ours .navbar-nav .nav-link a:hover {
  color: #ed912a !important;
}
.navbar-ours .navbar-toggler {
  background-color: #ed912a !important;
}
.navbar-ours #scroolthis {
  justify-content: space-evenly;
}
.navbar-ours #scroolthisleft {
  display: none;
  height: 150px;
  width: 150px;
}
.navbar-ours #center {
  width: 100px;
  transition: width 0.5s;
  transition-delay: 0.5s;
}
.navbar-ours .started {
  width: 0 !important;
}
.navbar-ours #scroolthisright {
  display: none;
  background-image: url("/assets/clefincode/images/ClefinCode-200.png");
  height: 150px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.navbar-ours2 {
  width: 100%;
  z-index: 10;
  display: table;
  background-color: #161e2e !important;
  color: #ed912a !important;
  transition: height 1s;
}

.web-footer .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.footer-copyright {
  background-color: #161e2e !important;
}

.navbar-ours1 {
  visibility: hidden;
  width: 100%;
  z-index: 10;
  display: table;
  transition: height 1s;
  background-color: #161e2e !important;
}
.navbar-ours1 .navbar-brand {
  color: #ed912a !important;
}
.navbar-ours1 .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
}
.navbar-ours1 .navbar-nav .nav-link a:hover {
  color: #ed912a !important;
}
.navbar-ours1 .navbar-toggler {
  background-color: #ed912a !important;
}
.navbar-ours1 #scroolthisleft1 {
  display: none;
  height: 150px;
  width: 150px;
}
.navbar-ours1 #scroolthisright1 {
  display: none;
  background-image: url("/assets/clefincode/images/ClefinCode-200.png");
  height: 150px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.padding-top > div[id=page-index] {
  padding-right: 0;
  padding-left: 0;
}
.padding-top > div[id^=page-]:not([data-path=pages]) {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.padding-top > div[id=page-youtube] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.navbar-ours #scroolthisleft {
  height: 90px;
  width: 90px;
}
.navbar-ours #scroolthisright {
  height: 90px;
  width: 90px;
}
.navbar-ours #center {
  width: 25px;
}

.navbar-ours1 #scroolthisleft1 {
  height: 90px;
  width: 90px;
}
.navbar-ours1 #scroolthisright1 {
  height: 90px;
  width: 90px;
}
.navbar-ours1 #center1 {
  width: 25px;
}

@media (min-width: 576px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .padding-top > div[id=page-youtube] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .navbar-ours #scroolthisleft {
    height: 100px;
    width: 100px;
  }
  .navbar-ours #scroolthisright {
    height: 100px;
    width: 100px;
  }
  .navbar-ours #center {
    width: 100px;
  }
  .navbar-ours1 #scroolthisleft1 {
    height: 100px;
    width: 100px;
  }
  .navbar-ours1 #scroolthisright1 {
    height: 100px;
    width: 100px;
  }
  .navbar-ours1 #center1 {
    width: 100px;
  }
}
@media (min-width: 768px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .padding-top > div[id=page-youtube] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .navbar-ours #scroolthisleft {
    height: 150px;
    width: 150px;
  }
  .navbar-ours #scroolthisright {
    height: 150px;
    width: 150px;
  }
  .navbar-ours1 #scroolthisleft1 {
    height: 150px;
    width: 150px;
  }
  .navbar-ours1 #scroolthisright1 {
    height: 150px;
    width: 150px;
  }
}
@media (min-width: 992px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .padding-top > div[id=page-youtube] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .navbar-ours #scroolthisleft {
    height: 180px;
    width: 180px;
  }
  .navbar-ours #scroolthisright {
    height: 180px;
    width: 180px;
  }
  .navbar-ours1 #scroolthisleft1 {
    height: 180px;
    width: 180px;
  }
  .navbar-ours1 #scroolthisright1 {
    height: 180px;
    width: 180px;
  }
}
@media (min-width: 1200px) {
  .padding-top > div[id=page-index] {
    padding-right: 0;
    padding-left: 0;
  }
  .padding-top > div[id^=page-]:not([data-path=pages]) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .padding-top > div[id=page-index]:not(:not([data-path="pages/main"])) {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .padding-top > div[id=page-youtube] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .navbar-ours #scroolthisleft {
    height: 200px;
    width: 200px;
  }
  .navbar-ours #scroolthisright {
    height: 200px;
    width: 200px;
  }
  .navbar-ours1 #scroolthisleft1 {
    height: 200px;
    width: 200px;
  }
  .navbar-ours1 #scroolthisright1 {
    height: 200px;
    width: 200px;
  }
}